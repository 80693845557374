{
    "Heisse Preise": "Hot Prices",
    "page_description": "Non-commercial open source project to enable consumers to find the cheapest version of a product in stores.",
    "Einstellungen": "Settings",
    "Impressum": "Imprint",
    "Logs": "Logs",
    "Historische Daten von": "Historic data from",
    "Alle Angaben ohne Gewähr, Irrtümer vorbehalten.": "All information provided without guarantee, errors excepted.",
    "Markennamen und Warenzeichen sind Eigentum der jeweiligen Inhaber.": "Brand names and trademarks are the property of their respective owners.",
    "Suche": "Search",
    "Preisänderungen": "Price changes",
    "Warenkörbe": "Shopping carts",
    "Noch keine Produkte im Warenkorb.": "No products in your shopping cart yet.",
    "Produkte suchen und mit '+' zum Warenkorb hinzufügen.": "Search for products and add them to the shopping cart with '+'.",
    "Filtern...": "Filter...",
    "(min. 3 Zeichen)": "(at least 3 characters)",
    "Produkt hinzufügen...": "Add product...",
    "Neuer Warenkorb": "New shopping cart",
    "Exportieren": "Export",
    "Importieren": "Import",
    "Medieninhaber": "Owner",
    "Kontakt": "Contact",
    "Adresse": "Address",
    "Diese nicht-kommerzielle Seite dient KonsumentInnen dazu, Preise von Produkten im Lebensmittelhandel vergleichen zu können.": "This non-commercial site allows consumers to compare prices of products in the grocery store.",
    "Video Anleitung": "Video instructions (in German)",
    "Text Anleitung": "Text instructions (in German)",
    "Medienberichte": "Media reports",
    "Produktsuche": "Product search",
    "Radio & Fernsehen": "Radio & Television",
    "Print & Online": "Print & Online",
    "CartsList_Name": "Name",
    "CartsList_Produkte": "Product",
    "CartsList_Preis": "Price",
    "CartsList_Preisänderungen": "Price changes",
    "CartsList_Teilen": "Share",
    "CartsList_JSON": "JSON",
    "CartsList_Löschen": "Delete",
    "ItemsChart_Keine Daten ausgewählt": "No data selected",
    "ItemsChart_Preissumme Gesamt": "Total price",
    "ItemsChart_Preissumme Ketten": "Store price",
    "ItemsChart_Nur heutige Preise": "Price today",
    "ItemsChart_Änderung in % seit": "Change in % since",
    "ItemsChart_Änderung in % seit {{date}}": "Change in % since {{date}}",
    "ItemsChart_Preissumme {{s}}": "Price {{s}}",
    "ItemsFilter_Produkte suchen...": "Product search...",
    "ItemsFilter_Filter anzeigen/ausblenden": "Show/hide filters",
    "ItemsFilter_Alle": "All",
    "ItemsFilter_Datum": "Date",
    "ItemsFilter_Billiger seit letzter Änderung": "Cheaper since last change",
    "ItemsFilter_Nur Diskont-Eigenmarken": "Discount store brands only",
    "ItemsFilter_Nur Bio": "Only bio",
    "ItemsFilter_Exaktes Wort": "Exact word",
    "ItemsFilter_Preis €": "Price €",
    "ItemsFilter_Teurer": "More expensive",
    "ItemsFilter_Billiger": "Cheaper",
    "ItemsList_Resultate": "Results",
    "ItemsList_Diagramm": "Chart",
    "ItemsList_Verkaufspreis": "Unit price",
    "ItemsList_Mengenpreis": "Bulk price",
    "ItemsList_Sortieren": "Sort by",
    "ItemsList_Preis aufsteigend": "Price ascending",
    "ItemsList_Preis absteigend": "Price descending",
    "ItemsList_Menge aufsteigend": "Quantity ascending",
    "ItemsList_Menge absteigend": "Quantity descending",
    "ItemsList_Kette &amp; Name": "Store chain &amp; name",
    "ItemsList_Namensähnlichkeit": "Name similarity",
    "ItemsList_Kette": "Store chain",
    "ItemsList_Name": "Name",
    "ItemsList_Preis": "Price",
    "Cart_Teilen": "Detail",
    "Cart_Speichern": "Save",
    "Cart_Warenkorb {{name}}": "Shopping cart {{name}}",
    "Cart_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu speichernden Warenkorb eingeben": "Shopping cart '{{name}}' already exists. Please enter a different name for the shopping cart to be saved",
    "Cart_Warenkorb '{{name}}' existiert nicht.": "Shopping cart '{{name}}' does not exist.",
    "Cart_Artikel": "Item",
    "Carts_Name für Warenkorb eingeben:": "Enter name for shopping cart:",
    "Carts_Warenkorb mit Namen '{{name}}' existiert bereits": "Shopping cart with name '{{name}}' already exists",
    "Carts_Warenkorb '{{name}}' existiert bereits. Bitte einen anderen Namen für den zu importierenden Warenkorb eingeben": "Shopping cart '{{name}}' already exists. Please enter a different name for the shopping cart to be imported",
    "Settings_Vorselektierte Ketten": "Pre-selected store chains",
    "Settings_Start-Datum für Diagramme": "Start date for charts",
    "Settings_Diagramm Typ": "Chart type",
    "Settings_Stufen": "Stepped",
    "Settings_Linien": "Lines",
    "Settings_Nur verfügbare Produkte anzeigen": "Show only available products",
    "Settings_Diagramm immer anzeigen (wenn verfügbar)": "Always show chart (when available)",
    "Settings_Suche immer anzeigen (wenn verfügbar)": "Always show search (when available)"
}
